const ConversationEnums = Object.freeze({
  ALL: 'all',
  ME: 'me',
  UNASSIGNED: 'unassigned',
  OPEN: 'open',
  REPLY: 'reply',
  PRIVATE: 'private',
  REPLY_BUTTON: 'Reply',
  PRIVATE_NOTE: 'Private Note',
  SEND: 'Send',
  HEADER: 'Header',
  ADD_NOTE: 'Add Note',
  FILTER_KEYS: {
    STATUS: 'status',
    EQUALS_TO: 'equal_to',
    STANDARD: 'standard',
    ADDITIONAL_ATTRIBUTES: 'additional_attributes',
    TEXT: 'text',
    MULTI_SELECT: 'multi_select',
    ASSIGNEE_ID: 'assignee_id',
    ASSIGNEE_NAME: 'Assignee Name',
    CONTACT_NAME: 'Contact Name',
    CAMPAIGN_NAME: 'Campaign Name',
    INBOX_NAME: 'Inbox Name',
    TEAM_NAME: 'Team Name',
    CONTACT: 'contact',
    LABELS: 'labels',
    INBOX_ID: 'inbox_id',
    DISPLAY_ID: 'display_id',
    TEAM_ID: 'team_id',
    CAMPAIGN_ID: 'campaign_id',
    COUNTRY_ID: 'country_code',
    BROWSER_LANGUAGE: 'browser_language',
    CHECKBOX: 'checkbox',
    BOOLEAN: 'boolean',
    REFERER: 'referer',
    REFERER_LINK: 'Referer link',
    DATE: 'date',
    DAYS_BEFORE: 'days_before',
    IS_X_DAYS_BEFORE: 'Is X Days Before',
    SEARCH_BOX: 'search_box',
    NUMBER: 'number',
    LINK: 'link',
    LIST: 'list',
    PLAIN_TEXT: 'plain_text',
    CAPITALIZATION_TRUE: 'True',
    CREATED_AT: 'created_at',
    CAPITALIZATION_CREATED_AT: 'Created At',
    LAST_ACTIVITY_AT: 'last_activity_at',
    NO_OPTIONS: 'No Options',
    CAPITALIZATION_LAST_ACTIVITY_AT: 'Last Activity',
    CAPITALIZATION_LABELS: 'Labels',
    CAPITALIZATION_FALSE: 'False',
    CAPITALIZATION_OPEN: 'Open',
    CAPITALIZATION_RESOLVED: 'Resolved',
    CAPITALIZATION_PENDING: 'Pending',
    RESOLVED: 'resolved',
    PENDING: 'pending',
    CAPITALIZATION_SNOOZED: 'Snoozed',
    CAPITALIZATION_BROWSER_LANGUAGE: 'Browser Language',
    SNOOZED: 'snoozed',
    CAPITALIZATION_ALL: 'All',
    CAPITALIZATION_STATUS: 'Status',
    ALL: 'all',
    CAPITALIZATION_AND: 'And',
    AND: 'and',
    CAPITALIZATION_OR: 'Or',
    OR: 'or',
    FALSE: 'false',
    TRUE: 'true',
    DATA_TYPE: 'data_type',
    INPUT_TYPE: 'input_type',
    NUMBER_MAX_INPUT_VALUE: 998,
  },
  IDENTIFIERS: {
    LABEL: 'label',
    VALUE: 'value',
    ID: 'id',
    NAME: 'name',
    TITLE: 'title',
    COUNTRY_CODE: 'country_code',
    COUNTRY_NAME: 'Country Name',
    CUSTOM_ATTRIBUTES: 'Custom Attributes',
    PRESENT: 'present',
    IS_PRESENT: 'is_present',
    IS_NOT_PRESENT: 'is_not_present',
    EQUALS_TO: 'equal_to',
    NOT_EQUAL_TO: 'not_equal_to',
    CONTAINS: 'contains',
    DOES_NOT_CONTAINS: 'does_not_contain',
    IS_GREATER_THAN: 'is_greater_than',
    IS_LESS_THAN: 'is_less_than',
    EXACTLY_EQUAL_TO: 'exactly_equal_to',
    FILTER_OPERATORS: 'filter_operators',
    CONVERSATION_IDENTIFIER: 'Conversation Identifier',
    CONVERSATION_ATTRIBUTE: 'conversation_attribute',
    CONVERSATION: 'conversation',
    REQUIRED_FIELD: 'This field is required',
    NO_NEGATIVE_VALUE_FIELD: 'Value should be greater than 0',
  },
  ENTER: 'Enter',
  OBJECT: 'object',
  CUSTOM_VIEW: 'custom_view',
  CC: 'CC',
  BCC: 'BCC',
  ARROW_UP: 'arrowup',
  ARROW_DOWN: 'arrowdown',
  ENTER_KEY: 'enter',
  WHATSAPP_CHANNEL: 'Channel::Whatsapp',
  SMS_CHANNEL: 'Channel::TataSmsc',
  EMAIL_CHANNEL: 'Channel::Email',
  INCOMING_EMAIL: 'incoming_email',
  VALID_EMAIL: 'Please enter valid email format',
  INPUT_CSAT: 'input_csat',
  TEXT_INPUT: '<p><br></p>',
  BUTTON: {
    RECORD_AUDIO: 'RECORD_AUDIO',
    SHOW_EMOJI_SELECTOR: 'SHOW_EMOJI_SELECTOR',
    ATTACH_FILES: 'ATTACH_FILES',
    SHOW_TEXT_EDITOR: 'SHOW_TEXT_EDITOR',
    SIGNATURE: 'SIGNATURE',
  },
  TYPING_STATUS: {
    TYPING_OFF: 'typing-off',
    TYPING_ON: 'typing-on',
    ON: 'on',
    OFF: 'off',
    TIMER: 4000,
  },
  IMG: 'IMG',
  VIEDO: 'VIDEO',
  FILE_TYPES: {
    AUDIO: 'audio',
    VIDEO: 'video',
    FILE: 'file',
    IMAGE: 'image',
  },
});

export default ConversationEnums;
